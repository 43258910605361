import { register } from 'swiper/element/bundle';
import { Pagination } from 'swiper/modules';
import Swiper from 'swiper';
register();

document.addEventListener('DOMContentLoaded', () => {
  const heroSwiperElements = document.querySelectorAll('.js-slider');
  const entriesSwiperElements = document.querySelectorAll('.js-entries-slider');
  const parents = document.querySelectorAll('#menu-main-menu > li > a');
  const parts = document.querySelectorAll('.parts .part');
  const partsToggleButtons = document.querySelectorAll('.parts-toggle__button');
  const partMobilePrevButton = document.getElementById('Part_Prev');
  const partMobileNextButton = document.getElementById('Part_Next');
  const mobileMenuTriggerWrapper = document.getElementById('MobileMenuTriggerWrapper');
  const mobileMenuTrigger = document.getElementById('MobileMenuTrigger');
  const mainMenuWrapper = document.getElementById('MainMenuWrapper');
  const mobileMenuWrapper = document.getElementById('MainMenu');
  const mobileExtras = document.getElementById('MobileExtras');
  const mobileMenuParents = document.querySelectorAll('#MainMenu .ground > li.menu-item-has-children > a');
  const customProductOptions = document.querySelectorAll('.single_variation_wrap input[type=radio], .variations input[type=radio]');
  const addToCartButton = document.querySelector('body.woocommerce .clar-main .product .single_add_to_cart_button');
  const fabricChoosers = document.querySelectorAll('.fabric-color-selector.parent');
  const fabricColorChoosers = document.querySelectorAll('.fabric-color-selector.child');
  const fabricColorsModalCloseButtons = document.querySelectorAll('.product__fabric-modal .close-colors-modal');
  
  let currentPartIndex = 0;
  let customProductOptionsPresent = [];
  
  // Listen to woocommerce variation price change
  jQuery(".variations_form").on("woocommerce_variation_select_change", function() {
    setTimeout(() => {
      const price = document.querySelector('.woocommerce div.product span.price');
      const priceTarget = document.querySelector('.woocommerce div.product .woocommerce-variation-add-to-cart p.price');
      
      if (price && priceTarget) {
        priceTarget.innerHTML = price.innerHTML;
      }
    }, 50);
  });
  
  // Product Swiper powered gallery
  const productPreviews = document.getElementById('ProductPreviews');
  const productThumbnails = document.getElementById('ProductThumbnails');
  const productPreviewNextButton = document.getElementById('ProductPreviewNext');
  const productPreviewPrevButton = document.getElementById('ProductPreviewPrev');
  
  const productThumbnailsSwiper = new Swiper(productThumbnails, {
    direction: "vertical",
    spaceBetween: 28,
    slidesPerView: 'auto',
    freeMode: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    watchOverflow: true,
  });
  
  new Swiper(productPreviews, {
    spaceBetween: 5,
    navigation: {
      nextEl: productPreviewNextButton,
      prevEl: productPreviewPrevButton,
    },
    thumbs: {
      swiper: productThumbnailsSwiper,
    },
  });
  
  // Product gallery - lightbox
  const lightbox = GLightbox({
    selector: '.js-gallery a',
    touchNavigation: true,
    loop: true,
  });
  
  // Choose fabric & color on product page
  fabricChoosers.forEach(chooser => {
    chooser.addEventListener('click', (event) => {
      event.preventDefault();
      document.getElementById(`FabricColors_${chooser.dataset.fabricId}`).classList.add('opened');
    });
  });
  
  // Close fabric color choose modals
  fabricColorsModalCloseButtons.forEach(btn => {
    btn.addEventListener('click', () => {
      document.querySelectorAll('.product__fabric-modal').forEach(modal => {
        modal.classList.remove('opened');
      });
    });
  });
  
  // Choose color and do some magic
  fabricColorChoosers.forEach(color => {
    color.addEventListener('click', () => {
      const choosenColorName = color.dataset.name;
      const choosenColorImgUrl = color.dataset.imgUrl;
      const choosenColorParentName = color.dataset.parentName;
      const choosenColorParentId = color.dataset.parentId;
      const parentToChange = document.querySelector(`#FabricParentID_${choosenColorParentId}`);
      
      // Reset all choosers to default state on child color choose
      fabricChoosers.forEach(chooser => {
        chooser.querySelector('.img-wrapper > img').src = chooser.dataset.defaultImage;
        chooser.querySelector('.content-wrapper span i').innerText = 'click to choose';
      });
      
      parentToChange.querySelector('img').src = choosenColorImgUrl;
      parentToChange.querySelector('.content-wrapper span i').innerText = choosenColorName;
      parentToChange.previousElementSibling.value = `${parentToChange.previousElementSibling.value}${choosenColorName}`;
      
      document.querySelectorAll('.product__fabric-modal').forEach(modal => {
        modal.classList.remove('opened');
      });
      
      document.getElementById(`product_fabric_${choosenColorParentName.toLowerCase().replace(' ', '-')}`).checked = true;
    });
  });
  
  // Mobile menu open / close
  if (mobileMenuTrigger) {
    mobileMenuTrigger.addEventListener('click', ev => {
      mobileMenuWrapper.classList.toggle('opened');
      mobileMenuTrigger.classList.toggle('opened');
      mobileMenuTriggerWrapper.classList.toggle('opened');
      mainMenuWrapper.classList.toggle('opened');
    });
  }
  
  // Mobile menu - accordion - styled navigation
  if (mobileMenuParents && window.innerWidth < 1280) {
    mobileMenuParents.forEach(parent => {
      
      // Just open submenu on single click
      parent.addEventListener('click', ev => {
        ev.preventDefault();
        
        ev.currentTarget.parentNode.classList.toggle('opened');
      });
      
      // Go to url on double click
      parent.addEventListener('dblclick', ev => {
        window.location.href = ev.currentTarget.href;
      });
      
    });
  }
  
  // Main menu titles place inside submenus
  if (parents) {
    parents.forEach(parent => {
      const hasChildren = parent.parentNode.querySelector('.submenu');
      
      if (hasChildren) {
        parent.parentNode.querySelector('.submenu .menu-title').innerText = parent.innerText;
      }
    });
  }
  
  // Decorative slider custom script
  if (partsToggleButtons) {
    partsToggleButtons.forEach((btn, index) => {
      btn.addEventListener('click', () => {
        parts.forEach(part => part.classList.remove('current'));
        partsToggleButtons.forEach(clickedBtn => clickedBtn.classList.remove('current'));
        parts[index].classList.add('current');
        btn.classList.add('current');
      });
    });
  }
  
  // Decorative slider on mobile
  if (partMobilePrevButton && partMobileNextButton) {
    partMobilePrevButton.addEventListener('click', () => {
      parts[currentPartIndex].classList.remove('current');
      currentPartIndex = (currentPartIndex - 1 + parts.length) % parts.length;
      parts[currentPartIndex].classList.add('current');
    });
    
    partMobileNextButton.addEventListener('click', () => {
      parts[currentPartIndex].classList.remove('current');
      currentPartIndex = (currentPartIndex + 1) % parts.length;
      parts[currentPartIndex].classList.add('current');
    });
  }
  
  // Hero slider
  if (heroSwiperElements) {
    heroSwiperElements.forEach(el => {
      const heroSwiper = new Swiper(el, {
        navigation: {
          nextEl: el.querySelector('.section-hero-slider__button-next'),
          prevEl: el.querySelector('.section-hero-slider__button-prev'),
        },
        autoplay: {
          delay: 7000,
        },
        loop: true
      });
    });
  }
  
  // Entries slider
  if (entriesSwiperElements) {
    entriesSwiperElements.forEach(el => {
      const entriesSwiper = new Swiper(el, {
        navigation: {
          nextEl: el.querySelector('.entries-slider__button-next'),
          prevEl: el.querySelector('.entries-slider__button-prev'),
        },
        scrollbar: {
          el: el.querySelector('.swiper-scrollbar'),
        },
        slidesPerView: 1,
        spaceBetween: 26,
        breakpoints: {
          465: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 4
          }
        }
      });
    });
  }
  
  // Menu thumbnails hack described in the end of wp-content/themes/clar/assets/css/styles.scss file
  // Thumbs are displayed as hidden inside every list element, this script just cuts them and places inside submenus
  document.querySelectorAll('.submenu__thumbs').forEach(thumbsItem => {
    const targetSubmenu = thumbsItem.parentNode.querySelector('.submenu .submenu__items');
    
    targetSubmenu.parentNode.insertBefore(thumbsItem, targetSubmenu.nextSibling);
    thumbsItem.classList.remove('hidden');
  });
  
  // Overwrite default "Add to cart" button behavior
  // Collect present custom options groups
  const collectCustomProductOptions = () => {
    customProductOptions.forEach(opt => {
      customProductOptionsPresent.push(opt.name);
    });
    
    if (customProductOptionsPresent.length)addToCartButton.disabled = true;
    
    // Store only unique radio names
    customProductOptionsPresent = [...new Set(customProductOptionsPresent)];
  }
  
  // Check radio inputs check states
  const checkCustomOptionsState = () => {
    for (let i = 0; i < customProductOptionsPresent.length; i++) {
      let options = document.getElementsByName(customProductOptionsPresent[i]);
      let oneChecked = false;
      
      for (let j = 0; j < options.length; j++) {
        if (options[j].checked) {
          oneChecked = true;
          break;
        }
      }
      
      if (!oneChecked) {
        // console.log(`Please choose an option from ${customProductOptionsPresent[i]} group`); // Debug purposes only
        return false;
      }
    }
    
    return true;
  }
  
  collectCustomProductOptions();
  
  // We need to use jQuery here because of WooCommerce
  jQuery('body').on('change', '.variations_form .variations select, .variations_form input, .variations input', function() {
    addToCartButton.disabled = !checkCustomOptionsState();
  });
  
  // Fix Safari multi-tap issue on mobiles
  const safariDoubleTapFix = () => {
    const links = document.querySelectorAll('.product-gallery-summary .botiga-single-addtocart-wrapper .button, .single-product .content-wrapper a, .single-product .footer-widgets a, .single-product .site-footer a');
    
    if( ! links.length ) {
      return false;
    }

    for( let i = 0; i < links.length; i++ ) {
      links[i].addEventListener('touchend', function() {})
    }
  }

  safariDoubleTapFix();

  // Product reviews slider
  new Swiper(document.querySelector('.reviews__slider'), {
    modules: [ Pagination ],
    spaceBetween: 15,
    loop: true,
    autoplay: {
      delay: 5000
    },
    slidesPerView: 1,
    pagination: {
      clickable: true,
      el: '.swiper-pagination',
      type: 'bullets',
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      960: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    }
  })

  // Global search field behavior
  const searchBtn = document.getElementById('SVGSearchIconWrapper');
  const searchField = document.getElementById('SearchField');

  const hideElements = () => {
    document.getElementById('SVGUserIconWrapper').classList.add('hidden');
    document.getElementById('SVGFavsIconWrapper').classList.add('hidden');
    document.getElementById('SVGCartIconWrapper').classList.add('hidden');
    document.getElementById('menu-main-menu').classList.add('hidden');
  }

  const showElements = () => {
    document.getElementById('SVGUserIconWrapper').classList.remove('hidden');
    document.getElementById('SVGFavsIconWrapper').classList.remove('hidden');
    document.getElementById('SVGCartIconWrapper').classList.remove('hidden');
    document.getElementById('menu-main-menu').classList.remove('hidden');
  }

  searchBtn.addEventListener('click', (e) => {
    e.preventDefault();

    searchField.classList.toggle('open');
    searchBtn.classList.toggle('open');
    searchField.classList.contains('open') && searchField.focus();
    searchField.classList.contains('open') ? hideElements() : showElements();
  });

  // Open / close user menu
  const userMenuWrapper = document.getElementById('SVGUserIconWrapper');
  const userMenuElement = document.querySelector('#SVGUserIconWrapper > .floating-menu');

  if ( userMenuElement ) {
    userMenuWrapper.addEventListener('click', () => {
      userMenuElement.classList.add('active');
    });
    document.addEventListener('click', ev => {
      if (!userMenuWrapper.contains(ev.target)) {
        userMenuElement.classList.remove('active');
      }
    });
  }

  // Tabs (e.g. on dingle product page)
  const tabsSource = document.getElementById('JS_tabs_source');
  const tabsTarget = document.getElementById('JS_tabs_target');

  tabsSource && tabsSource.addEventListener('click', ev => {
    ev.preventDefault();

    const tabsSources = tabsSource.querySelectorAll('a');
    const tabsTargets = tabsTarget.querySelectorAll('.product-tabs__target');

    if (ev.target.nodeName === 'A') {
      const tabToActivate = Array.from(ev.target.parentNode.children).indexOf(ev.target);

      tabsSources.forEach(el => el.classList.remove('active'));
      tabsTargets.forEach(el => el.classList.remove('active'));

      tabsTargets[tabToActivate].classList.add('active');
      ev.target.classList.add('active');
    }
  });

  // Nicely show gallery photos on scroll
  const nicelyShowedElements = [...document.querySelectorAll('[data-nicely-show]')];

  const options = {
    root: document,
    rootMargin: "0%",
    threshold: .5
  };

  const onIntersect = (entries, observer) => {
    entries.forEach(entry => {
      let element = entry.target;

      if (entry.isIntersecting) {
        element.classList.add('on');
        observer.unobserve(entry.target);
      }
    })
  }

  const observer = new IntersectionObserver(onIntersect, options);

  if (nicelyShowedElements.length) {
    nicelyShowedElements.forEach(element => {
      observer.observe(element)
    });
  }
  
  // Read less/more in reviews slider
  if (document.querySelectorAll('.js-readmore').length) {
    document.querySelectorAll('.js-readmore').forEach((review, i) => {
      const chunks = review.innerHTML.match(/.{1,230}(?:\s|$)/g); // Split text into two chunks after 230 characters which is less or more than 6 lines of text
      review.innerHTML = ''; // Remove current text
      
      // Build new HTML inside review based on chunks
      review.innerHTML = `
        ${chunks[0]} ${chunks[1] ? '<input type="checkbox" id="rev_' + i + '" /><label for="rev_' + i + '">... <span>Read more</span></label> <span>' + chunks[1] + '</span>' : ''}
      `;
    });
  }
  
});

// Force click on labels in product configurator on mobile Safari
// Because of strange bug with hover -> tap on iPhone
document.addEventListener('touchstart', function(event) {
  const label = event.target.closest('label.product__pref-tile');
  if (label && label.htmlFor) {
    const input = document.getElementById(label.htmlFor);
    if (input) {
      input.focus();
      input.click();
    }
  }
});
